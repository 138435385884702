.alertBar
{
  margin-bottom: 35px;
}

.mainPanes
{
  padding: 0 16px;
}

.hideOverflow
{
  overflow-y: hidden;
}

.cardOuter
{
  margin: 8px 0;
}

.timerContainer,
.timerBody
{
  height: 2px;
}

.timerContainer
{

}

.timerBody
{
  width: 100%;
  background-color: red;
}
.timerBody.runTimer
{
  width: 0%;
  transition: width 300s linear 0s;
}